<template>
  <div :class="isDuasLetras ? 'icone-dois' : 'icone-um'">
    <span>{{ letra }}</span>
  </div>
</template>

<style>
.icone-um {
 border: solid 1px;
 border-radius: 50%;
 width: 15px;
 height: 15px;
 font-size: 11px;
 font-weight: bold;
}

.icone-dois {
 border: solid .1147rem;
 border-radius: 50%;
 width: 17px;
 height: 17px;
 display: grid;
 align-content: center;
 justify-content: center;
 font-size: 9px;
 font-weight: bolder;
}
</style>

<script>
export default {
  name: 'IconeLetra',
  components: {
  },
  props: {
    letra: {
      type: String,
      required: true,
    },
    isDuasLetras: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
